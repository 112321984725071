import axios from 'axios';
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { Store } from 'react-notifications-component';
import { notification } from '../../utils/notification';
import { setSession } from '../../utils/jwt';
import { ApiResponse, Props } from '../hooks/useRegister';
import { LoginResponse } from '../hooks/useLogin';
import { forgetPasswordResponse, Props as newProps } from '../hooks/useForgetPassword';
import { ApiForgetOtpResponse } from '../hooks/useVerifyForgetOtp';
import { ApiResponse as props } from '../hooks/useVerifyUser';

class Response {
  registerResponse = (data: ApiResponse, user: Props) => {
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: 'successfully registered'
    });
    user.email &&
      setTimeout(() => {
        window.location.href = `/oauth/authorize/verification/automatedPin/${data?.message?.activation_token}/${
          user.email
        }/${1}/${user.roleId}/${'email'}`;
      }, 1000);
    user.phone &&
      setTimeout(() => {
        window.location.href = `/oauth/authorize/verification/automatedPin/${data?.message?.activation_token}/${
          user.phone
        }/${1}/${user.roleId}/${'phone_number'}`;
      }, 1000);
  };
  emailVerificationResponse = (data: props) => {
    if (data.data?.isActivated) {
      // Retrieve the main app's origin from sessionStorage
      const mainAppOrigin = sessionStorage.getItem('mainAppOrigin');
      window.opener.postMessage({ accessToken: data.data?.token }, mainAppOrigin); // use a specific origin instead of '*' for security

      setTimeout(() => {
        window.close();
      }, 1000);
    }

  };
  loginResponse = (data: LoginResponse) => {
    if (data.data?.isActivated) {
      // Send accessToken to the main app via postMessage

      // Retrieve the main app's origin from sessionStorage
      const mainAppOrigin = sessionStorage.getItem('mainAppOrigin');
      window.opener.postMessage({ accessToken: data.data?.token }, mainAppOrigin); // use a specific origin instead of '*' for security

      setTimeout(() => {
        window.close();
      }, 1000);
    }

    // if (!data.otp_verified || data.data['Login Data']?.otp_verified === 0) {
    //   const unverified = data.data['Unverified Login Data'];

    //   setTimeout(() => {
    //     window.location.href = `/oauth/authorize/verification/${data.data.pinId || 'automatedPin'}/${
    //       data.data?.token
    //     }/${unverified?.phone_number || unverified?.email}/${unverified?.id}/${unverified?.role}/${
    //       unverified?.email === null ? 'phone_number' : 'email'
    //     }`;
    //   }, 1000);
    // }
  };
  //******** */ note this endpoint is expecting the role....
  forgetPasswordResponse = (data: forgetPasswordResponse, variable: newProps) => {
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setTimeout(() => {
      window.location.href = `/oauth/authorize/password-reset-otp/${'automatedPin'}/${data?.data.user.id}/${
        emailRegex.test(variable.identity) ? 'email' : 'phone_number'
      }/${'role'}/${data?.data.user.forgot_password_token}`;
    }, 1000);
  };
  forgetPasswordVerifyOtpResponse = (data: ApiForgetOtpResponse) => {
    console.log(data, 'this is the response getting here');
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    setTimeout(() => {
      window.location.href = `/oauth/authorize/change-password/${data.data.user.id}/${data?.data?.user?.reset_password_token}`;
    }, 1000);
    // setTimeout(() => {
    //   window.location.href = `/oauth/authorize/change-password/${payload.role}/${nanoid(35)}`;
    // }, 1000);
  };
  resetPasswordResponse = (data: any) => {
    Store.addNotification({
      ...notification,
      type: 'success',
      container: 'top-center',
      insert: 'top',
      title: 'Success',
      message: data.message
    });
    setTimeout(() => {
      window.location.href = `/oauth/authorize/login/fromUmlAssign/${`MarketPlace`}/initiator_id/${nanoid(35)}`;
    }, 1000);
  };
  cleanPasswordResponse = (data: any) => {
    setTimeout(() => {
      window.location.href = `/oauth/authorize/login/fromUmlAssign/${`MarketPlace`}/initiator_id/${nanoid(35)}`;
    }, 1000);
  };
}
export default Response;
