// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import ReactCodeInput from 'react-code-input';
import { useFormikContext } from 'formik';
import { Stack, styled } from '@mui/material';
import { ErrorText } from './ErrorText';
import './Otpinput.css'

interface IProps {
  name: string;
  [propName: string]: any;
}

const StyledPinInput = styled(ReactCodeInput)({});

const props = {
  inputStyle: {
    MozAppearance: 'textfield',
    margin: 'auto 4px',
    width: '55px',
    borderRadius: '8px',
    fontSize: '48px',
    fontWeight: 500,
    height: '64px',
    padding: '0 11px',
    color: '#4ca30d',
    border: '1px solid #A6EF67',
    overflow: 'hidden'
  },
  inputStyleInvalid: {
    MozAppearance: 'textfield',
    margin: 'auto 4px',
    width: '55px',
    borderRadius: '8px',
    fontSize: '48px',
    fontWeight: 500,
    height: '64px',
    paddingLeft: '18px',
    color: 'red',
    border: '1px solid red'
  }
};

const OtpInput = ({ name, ...others }: IProps) => {
  const { setFieldTouched, handleChange, errors, touched } = useFormikContext<IProps>();
  return (
    <Stack direction={'column'} alignItems={'flex-start'} spacing={1}>
      <StyledPinInput
        fields={6}
        type={'number'}
        inputMode={'numeric'}
        name={name}
        placeholder={'0'}
        onChange={handleChange(name)}
        touch={() => setFieldTouched(name)}
        {...props}
        {...others}
      />
      <ErrorText error={errors[name]} visible={touched[name]} />
    </Stack>
  );
};

export default OtpInput;
