// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
padding:0;
margin: 0;
box-sizing: border-box;
}
.success {
    color: #3c763d;
    background-color: #dff0d8;
    padding: 12px 10px;
    border-radius: 4px;
    margin: 10px 0;
    width:100%;
    font-size: 14px;
}
.error {
    padding: 14px 10px;
    background: #fee6e8;
    border-radius: 4px;
    color: #f74d5a;
    margin: 10px 0;
    text-align: center;
    width:100%;
    font-size: 14px;

}`, "",{"version":3,"sources":["webpack://./src/components/Notification/error.css"],"names":[],"mappings":"AAAA;AACA,SAAS;AACT,SAAS;AACT,sBAAsB;AACtB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,eAAe;;AAEnB","sourcesContent":["*{\npadding:0;\nmargin: 0;\nbox-sizing: border-box;\n}\n.success {\n    color: #3c763d;\n    background-color: #dff0d8;\n    padding: 12px 10px;\n    border-radius: 4px;\n    margin: 10px 0;\n    width:100%;\n    font-size: 14px;\n}\n.error {\n    padding: 14px 10px;\n    background: #fee6e8;\n    border-radius: 4px;\n    color: #f74d5a;\n    margin: 10px 0;\n    text-align: center;\n    width:100%;\n    font-size: 14px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
