// import { useQuery } from '@tanstack/react-query';
// import { APIClient, setAuthToken } from '../services/apiClient';

// interface Props {
//   success: boolean;
//   message: string;
//   errors: null;
// }
// // Set the token for endpoints that require it

// const useResendOtp = (token: string, params: 'UPDATE_PROFILE' | 'ACTIVATION' | 'FORGOT_PASSWORD') => {
//   const apiClient = new APIClient<Props>(`/account/resend_otp/${params}`);
//   setAuthToken(token);
//   // Use the useQuery hook from react-query to fetch data
//   return useQuery<Props, Error>({
//     queryKey: ['resend_otp'],
//     queryFn: apiClient.get,
//     enabled: false
//   });
// };
// export default useResendOtp;


// import apiClient from "../services/api-client";
import { APIClient } from '../services/apiClient';
import { useMutation } from '@tanstack/react-query';
import Response from '../response/response';
import ErrorResponse from '../response/error';
import { Store } from 'react-notifications-component';
import { notification } from '../../utils/notification';

export interface RequestProps {
  type?: 'UPDATE_PROFILE' | 'ACTIVATION' | 'FORGOT_PASSWORD';
  activation_token: string;
}
export interface LoginResponse {
  success: boolean;
  message: string;
  data: {
    activation_token: string;
  } ,
  errors: null;
}


export class RequestError extends Error {
  response: any; // Adjust the type based on the response structure you expect

  constructor(message: string, response: any) {
    super(message);
    this.response = response;
  }
}
const response = new Response();
const errorResponse = new ErrorResponse();
const apiClient = new APIClient<LoginResponse, RequestProps>('/account/resend_otp');
const useResendOtp = () => {
  return useMutation<LoginResponse, RequestError, RequestProps>((newData: RequestProps) => apiClient.post(newData), {
    // onSuccess: (data, variable) => {
 
    // },
    onError: (error, variables) => {
      if (error.response) {
        Store.addNotification({
          ...notification,
          type: "danger",
          container: 'top-center',
          insert: 'top',
          title: 'Error',
          message: error?.response?.data?.message || error?.message
        });
      }
    }
  });
};
export default useResendOtp;




