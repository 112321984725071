import React from 'react';
import { FormControl, FormLabel, Typography, Select, MenuItem, Box } from '@mui/material';
import { IoIosArrowDown } from 'react-icons/io';
import { startCase } from 'lodash';
import { useFormikContext } from 'formik';
import { LabelStyle } from './formWrapper.style';
import { ErrorText } from './ErrorText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface IProps {
  name: string;
  label: string;
  data: any[];
  placeholder?: string;
  [propName: string]: any;
}

const selectStyle = {
  lineHeight: 1.5,
  borderRadius: '8px',
  outline: 'none',
  padding: 0,

  '&:hover': {
    borderColor: '#D0D5DD',
    outline: 'none !important'
  },

  '& .MuiSelect-select': {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: '#101828',
    px: 1,
    py: 1.4
  }
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const menuItemStyle = {
  fontSize: '1.4rem'
};

const SelectInput = ({ name, label, data, placeholder, ...others }: IProps) => {
  const { touched, errors, values, handleChange } = useFormikContext<IProps>();
  return (
    <FormControl fullWidth>
      <Typography component={FormLabel} sx={{ ...LabelStyle }}>
        {startCase(label)}
      </Typography>

      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        displayEmpty
        onChange={handleChange}
        IconComponent={IoIosArrowDown}
        sx={{ ...selectStyle, borderColor: touched[name] && errors[name] ? '#B42318' : '#D0D5DD' }}
        defaultValue={1}
        MenuProps={MenuProps}
        name={name}
        value={values[name]}
        {...others}
      >
        <MenuItem value={''} disabled sx={{ ...menuItemStyle }}>
          {placeholder}
        </MenuItem>
        {data.map((x) => (
          <MenuItem value={x.value} key={x.label} sx={{ ...menuItemStyle }}>
            {x.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorText error={errors[name]} visible={touched[name]} />
    </FormControl>
  );
};

export default SelectInput;
