import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import AuthScreensLayout from '../layouts/AppLayout';
import { AuthCardView, Headline } from '../components/auth';
import { OtpInput } from '../components/forms';
import { MainButton } from '../components/buttons';
import { nanoid } from 'nanoid';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';
import { useLocation} from 'react-router-dom'

import {
  FORGET_PASSWORD_VERIFY_OTP,
  PASSWORD_RESET_PHONE_VERIFY_OTP,
  PASSWORD_RESET_VERIFY_EMAIL_OTP
} from '../redux/slice/auth/auth.actions';
// import { getFromSessionStorage } from '../../utils/saveToSessionStorage';/
import useVerifyForgetOtp from '../react-query/hooks/useVerifyForgetOtp';
import useResendOtp from '../react-query/hooks/useResendOtp';

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required')
    .min(6, 'OTP must be at least 6 characters')
    .max(6, 'OTP must be at most 6 characters')
});

const ForgetPasswordResetCode = () => {
  const location = useLocation();
  const { mutate, isLoading, error } = useVerifyForgetOtp();
  const { pinId, userCredential, registrationType, role, token } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // handle the reset of time when clicked...
  const initialTimerSeconds = 300; // 300 seconds (5 minutes)

  const [timerSeconds, setTimerSeconds] = useState<number>(
    parseInt(sessionStorage.getItem('otpTimerSeconds') || '0') || initialTimerSeconds
  );

  // Function to format the timer value to mm:ss
  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Function to start the timer
  const startTimer = () => {
    setTimerSeconds(initialTimerSeconds); // Reset the timer to 300 seconds (5 minutes)
  };

  // useEffect hook to decrement the timer every second
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timerSeconds > 0) {
      interval = setInterval(() => {
        setTimerSeconds((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      sessionStorage.setItem('otpTimerSeconds', timerSeconds.toString());
    };
  }, [timerSeconds]);

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      userCredential &&
        token &&
        mutate({
          otp: val.otp,
          token
          // userId: userCredential
        });
    }
  });

  // Automatically submit when OTP length is 6
  useEffect(() => {
    if (formik.values.otp.length === 6) {
      formik.submitForm();
    }
  }, [formik.values.otp]);

  // Use the useResendOtp hook only if the token exists
  const { mutate: resendMutate, data, error:resendError } = useResendOtp();
  const resendOTP = () => {   
    token &&
      resendMutate({
        activation_token: token,
        type: 'FORGOT_PASSWORD'
      });
     startTimer();
  };


  // whwnever the data changes
  useEffect(() => {
    if (data?.message) {
      const oldPath = '/oauth/authorize/password-reset-otp/automatedPin/5/email/role/';
      const newToken = data?.data?.activation_token
      const updatedPathname = `${oldPath}${newToken}`;

      console.log('Redirecting to:', updatedPathname);
      navigate(updatedPathname);
    }
  }, [data, navigate]);

  useEffect(()=>{

    if((resendError?.response?.data?.message || resendError?.message ) === 'jwt expired'){
      setTimeout(()=>{
        navigate('/oauth/authorize/forgot-password/fromUmlAssign/FaMaaS')
      }, 2000)
     
    }
  }, [resendError])


  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline
          title={'Password Reset Code'}
          subtitle={'Enter the 6-digit code sent to your phone number or email address'}
        />
        <AuthCardView>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Stack direction={'column'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                <OtpInput name={'otp'} />
                <MainButton
                  label={'Verify Account'}
                  color={'primary'}
                  type={'submit'}
                  fullWidth
                  padding={'10px 18px'}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </AuthCardView>

        <Stack direction={'column'} spacing={3} alignItems={'center'} justifyContent={'center'} marginTop={3}>
          <Typography
            variant={'body1'}
            component={'span'}
            fontSize={'1.4rem'}
            color={timerSeconds ? '#667085' : '#000'}
            sx={{ cursor: timerSeconds ? 'disable' : 'pointer' }}
            fontWeight={400}
            display={'inline-block'}
            onClick={!timerSeconds ? resendOTP : undefined}
          >
            Resend Code{' '}
            {timerSeconds && timerSeconds > 0 ? (
              <Typography variant={'body1'} component={'span'} color={'#3B7C0F'} fontSize={'1.4rem'} fontWeight={500}>
                {formatTime(timerSeconds)}
              </Typography>
            ) : (
              ''
            )}
          </Typography>

          <Typography
            variant={'body1'}
            component={'span'}
            color={'#667085'}
            fontSize={'1.4rem'}
            fontWeight={400}
            display={'inline-block'}
          >
            Remember your Password?{' '}
            <Typography
              variant={'body1'}
              component={'span'}
              color={'#3B7C0F'}
              fontSize={'1.4rem'}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(`/oauth/authorize/login/fromUmlAssign/${`MarketPlace`}/initiator_id/${nanoid(35)}`)
              }
            >
              Log in
            </Typography>
          </Typography>
        </Stack>
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default ForgetPasswordResetCode;
