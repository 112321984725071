import { useEffect } from 'react';
import Routers from './router/Router';
function App() {
  useEffect(() => {
    // Check if mainAppOrigin exists in the URL query string and store it in sessionStorage
    const urlParams = new URLSearchParams(window.location.search);
    const mainAppOrigin = urlParams.get('mainAppOrigin');

    if (mainAppOrigin) {
      // Store the mainAppOrigin in sessionStorage
      sessionStorage.setItem('mainAppOrigin', mainAppOrigin);
    }
  }, []); 
  return <Routers />;
}

export default App;
