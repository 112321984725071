import React from 'react';
import { FormControl, FormLabel, Typography, Box, Stack } from '@mui/material';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { startCase } from 'lodash';
import { useFormikContext } from 'formik';
import { FormField, FormWrapper, LabelStyle } from './formWrapper.style';
import { ErrorText } from './ErrorText';

interface IProps {
  label?: string;
  [propName: string]: any;
  onSubmit: any;
  name: string;
}

const FormAffixVerification = ({ label, onSubmit, name, ...others }: IProps) => {
  const { touched, errors, values } = useFormikContext<IProps>();

  const [formData, setFormData] = React.useState<any>({});
  const [isVerified, setIsVerified] = React.useState<boolean>(false);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(formData[name]);
    setIsVerified(true);
  };

  // console.log(errors[name]);

  return (
    <FormControl {...others}>
      <Typography component={FormLabel} sx={{ ...LabelStyle }}>
        {startCase(label)}
      </Typography>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          ...FormWrapper
        }}
      >
        <FormField
          type={'email'}
          name={name}
          value={values[name]}
          onChange={handleChange}
          placeholder={'john@gogeeper.com'}
        />
        {!isVerified && (
          <Typography
            component={'button'}
            padding={0}
            bgcolor={'transparent'}
            border={0}
            color={'#B42318'}
            fontWeight={500}
            fontSize={'1.4rem'}
            type={'button'}
            sx={{ cursor: 'pointer' }}
            onClick={handleSubmit}
          >
            Verify
          </Typography>
        )}

        {isVerified && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <IoMdCheckmarkCircleOutline color={'#12B76A'} size={18} />
            <Typography
              color={'#12B76A'}
              component={'button'}
              padding={0}
              bgcolor={'transparent'}
              border={0}
              fontWeight={500}
              fontSize={'1.4rem'}
              ml={0.5}
              type={'button'}
              disabled
            >
              Verified
            </Typography>
          </Stack>
        )}
      </Box>
      <ErrorText error={errors[name]} visible={touched[name]} />
    </FormControl>
  );
};

export default FormAffixVerification;
