import React from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { MainButton } from '../buttons';

interface IProps {
  label: string;
  name: string;
  showBadge?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;

  [propName: string]: any;
}

const ActionCarrierForm = ({ label, onSubmit, name, showBadge = false, disabled, ...otherProps }: IProps) => {
  const textInputStyle = {
    outline: 0,

    '& .MuiOutlinedInput-root': {
      fontSize: '1.4rem',
      '& fieldset': {
        borderColor: '#D0D5DD',
        borderRadius: '.8rem',
        padding: '10px 14px'
      },
      '&:hover fieldset': {
        borderColor: '#D0D5DD'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4CA30D'
      }
    }
  };

  return (
    <Box {...otherProps}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        spacing={2}
        mb={2.8}
        sx={{
          '@media screen and (max-width: 1200px)': {
            mb: 2
          }
        }}
      >
        <Typography
          fontWeight={600}
          fontSize={'1.8rem'}
          color={'#1D2939'}
          sx={{
            '@media screen and (max-width: 1200px)': {
              fontSize: '1.4rem'
            }
          }}
        >
          {label} &reg;
        </Typography>
        {showBadge && (
          <Box
            sx={{
              borderRadius: '16px',
              backgroundColor: '#F3FEE7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px 8px'
            }}
          >
            <Typography fontWeight={600} fontSize={'1.2rem'} color={'#3B7C0F'}>
              Coming soon
            </Typography>
          </Box>
        )}
      </Stack>

      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <TextField sx={{ flex: 1, ...textInputStyle }} disabled={disabled} />
        <Box ml={1.5}>
          <MainButton label={'Apply'} color={'primary'} padding={'10px 14px'} />
        </Box>
      </Stack>
    </Box>
  );
};

export default ActionCarrierForm;
