// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the blurred overlay */
.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.1); /* Dark semi-transparent overlay */
    z-index: 9999; /* Ensure it's on top of everything else */
    display:flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
  }
  
  /* Styles for the loading spinner */
  .loading-spinner {
    position: relative;
    z-index: 10000; /* Ensure it's on top of the overlay */
  }`, "",{"version":3,"sources":["webpack://./src/layouts/loadingLayout/loadingLayout.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,sCAAsC,EAAE,kCAAkC;IAC1E,aAAa,EAAE,0CAA0C;IACzD,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;EAC5B;;EAEA,mCAAmC;EACnC;IACE,kBAAkB;IAClB,cAAc,EAAE,sCAAsC;EACxD","sourcesContent":["/* Styles for the blurred overlay */\n.blur-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255,255,255,.1); /* Dark semi-transparent overlay */\n    z-index: 9999; /* Ensure it's on top of everything else */\n    display:flex;\n    justify-content: center;\n    align-items: center;\n    backdrop-filter: blur(2px);\n  }\n  \n  /* Styles for the loading spinner */\n  .loading-spinner {\n    position: relative;\n    z-index: 10000; /* Ensure it's on top of the overlay */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
